<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>错题集</el-breadcrumb-item>
    </el-breadcrumb>
    <el-collapse accordion v-if="!errorflag"
                 v-model="activeNames"
                 @change="handleChange"
                 class="question">
      <el-collapse-item v-for="(pitem,index) in datainfo"
                        :key="index"
                        :name="index+1">
        <template slot="title">
          <div class="flexBtw"
               style="width:100%">
            <span class="bold">{{pitem.classesinfo.Year}}{{pitem.classesinfo.Season}}{{pitem.classesinfo.SubSeason}}{{pitem.classesinfo.Subject}}{{pitem.classesinfo.ClassType}}{{pitem.classesinfo.GradeStart}}</span>
            <span class="color1"
                  v-if="activeNames==''">查看</span>
            <span class="color1"
                  v-else-if='activeNames==(index+1)'>收起</span>
            <span class="color1"
                  v-else>查看</span>
          </div>
        </template>
        <div v-if="pitem.mistakes.length==0" class="no-error">该讲表现良好，无错题</div>
        <el-table v-else :data="pitem.mistakes"
                  style="width: 100%"
                  :row-class-name="tableRowClassName">
          <el-table-column prop="LessonNo"
                           fixed
                           label="课程讲次"
                           align="center">
          </el-table-column>
          <el-table-column prop="TotalNum"
                           label="总题数" align="center">
          </el-table-column>
          <el-table-column prop="ErrorNum"
                           label="错题数" align="center">
          </el-table-column>
          <el-table-column label="错题详情">
            <template slot-scope="scope">
              <div class="handle">
                <p v-if="scope.row.ErrorNum>0" @click="godetail(pitem,scope.row.LessonNo)">查看详情</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>
    <div v-else class="errortips">{{errortips}}</div>
  </div>
</template>

<script>
import { classes_mistakes } from '@/api/classesinfo'
export default {
  name: 'questionset',
  data () {
    return {
      activeNames: 1,
      datainfo:[],
      userid:this.$store.state.user.stu_user_info[0].id,
      errorflag:false,
      errortips:''      
    };
  },
  created () {
    sessionStorage.removeItem('stu_question_query')
    if(this.$route.query.activeNames){
      this.activeNames=this.$route.query.activeNames
      this.handleChange(Number(this.activeNames))
    }
    this.getInit()
  },
  methods: {
        async getInit () {
      let prames = {
        userid: this.userid,
      }
      let res = await classes_mistakes(prames)
      if (res.flag == '1') {
        this.datainfo = res.datainfo
      } else if (res.flag == '-1') {
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      }else if (res.flag == '2') {
        this.errorflag=true;
        this.errortips=res.msg        
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'error',
        });
      }
    },
    //奇偶数背景色
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'even';
      } else if (rowIndex % 2 == 1) {
        return 'odd';
      }
      return '';
    },
    handleChange (val) {
      this.activeNames = val
    },
    godetail (item,LessonNo) {
      this.$router.push({
        path: '/questionset/questiondetail',
      })
      var query={
          userid: this.userid,
          DBType:item.classesinfo.DBType,
          OprId:item.classesinfo.OprId,
          LessonNo:LessonNo,
          activeNames:this.activeNames    
      }
      sessionStorage.setItem('stu_question_query',JSON.stringify(query) )
    }
  },

}
</script>
<style lang="scss" scoped>
.question {
  margin-top: 30px;
  border: none !important;
  .el-collapse-item {
    margin-bottom: 20px;
    border: 1px solid #e9e9ec;
    border-radius: 10px;
    overflow: hidden;
    background: #f1f2fa;
  }
  .no-error{
    padding-top: 25px;
    text-align: center;
    color: #969799;
    font-size: 16px;
  }
}
</style>
<style lang="scss">
.question {
  .el-collapse-item__header {
    border: none !important;
    background: none !important;
    padding: 10px 25px !important;
    font-size: 18px !important;
  }
  .el-collapse-item__arrow {
    color: #6f72ff;
  }
}
</style>